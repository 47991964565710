import logo from "./logo.svg";
import "./App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Appeal from "./img/appeal.jpg";
import Banner from "./img/banner.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Banner} />
      </header>
      <body>
        <Container>
          <Row>
            <Col>
              <h1>
                Bridging Communities with Systems Change: Advancing Health
                Equity and Promising Practices
              </h1>
              <hr />
              <p>
                APPEAL invites health departments, health systems, community
                leaders, coalitions, and others in the health community to build
                a bridge for health equity change in tobacco and cancer
                prevention and control. This web series will allow for diverse
                communities of color, culture, and intersectionality to collect
                strategic ideas for community empowered policy, systems, and
                environmental change to forward current health equity work in
                the Native Hawaiian, Pacific Islander, and Asian American
                communities. Participants will learn about and be given a chance
                to develop and create action plans to embed health equity for
                all from real case examples and be exposed to the resources
                available to ASPIRE partners such as individualized technical
                assistance training, toolkits, and fact sheets for replicating
                and adapting successful promising practices to their
                communities. Facilitated dialogue will allow for health
                departments and community based organizations to continue to
                build a network of partnerships for cancer and tobacco
                prevention and control.
              </p>
              <p>
                Join us on <strong>September 20-21, 2022</strong> for The
                Tobacco, Cancer, and Health Equity Web Series discussing
                critical racial and health equity issues particular in tobacco
                and cancer prevention and control and other areas of public
                health inequities. This is an opportunity to celebrate the
                collaboration of priority populations in combating cancer and
                tobacco related health disparities.
              </p>
              <p>
                This virtual web series will be an opportunity for both newer
                tobacco and cancer control advocates as well as those more
                experienced to participate and engage. The Web Series details
                are below:
              </p>
              <h2>Mission Statement</h2>
              <p>
                To advance equity in cancer and tobacco prevention and control
                by uplifting upstream community-centered approaches, dismantling
                institutional racism, and building community capacity and
                empowerment.
              </p>
              <p>
                The overall objectives of the conference are:
                <ol>
                  <li>
                    Set the stage for the role of state and local health
                    departments and community partnerships in cross cultural,
                    accountable equity work with minority groups
                  </li>
                  <li>
                    Identify the role of social determinants of health and
                    intersectionality in creating health disparities
                  </li>
                  <li>
                    Create a dialogue to mobilize the community to build
                    strategic systems, institutions, and community partnerships
                    with whole community representation
                  </li>
                  <li>
                    Uplift promising practices to advance health equity for all
                    and empower minority groups (Asian American and the Native
                    Hawaiian / Pacific Islander communities in particular) to
                    champion equity centered policy and culturally centered
                    education and outreach
                  </li>
                  <li>
                    Advance health equity through leveraging a community
                    centered asset based approach to capacity building and
                    empowering communities to civic engagement and leadership
                    development
                  </li>
                  <li>
                    Provide social norm changes through upstream work for
                    systems change
                  </li>
                  <li>
                    Use a health equity lens to identify tools and opportunities
                    to dismantle structural barriers to programming and policy{" "}
                  </li>
                </ol>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Sessions</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                Session 1: Native Hawaiian and Pacific Islander session with
                Affinity Group Promising Practices Document Highlights and
                Lessons Learned
              </h3>
              <p className="subheader">September 20th, 2022 - 3-4PM PST</p>
              <h4>Featured Speakers</h4>
              <p className="speakers">
                <ul>
                  <li>
                    <strong>Malcolm Ahlo</strong> -
                    <em>
                      Tobacco Prevention and Control Coordinator with the
                      Southern Nevada Health District
                    </em>
                  </li>
                  <li>
                    <strong>Jake Fitisemanu Jr.</strong> -
                    <em>
                      Program Manager with the Utah Department of Health and
                      Associate Instructor at the University of Utah
                    </em>
                  </li>
                  <li>
                    <strong>Joanne Ogo</strong> -
                    <em>
                      Program Coordinator with the Department of Public Health -
                      Comprehensive Cancer Control Program and CNMI Cancer
                      Registrar
                    </em>
                  </li>
                </ul>
              </p>

              <h4>Objectives</h4>
              <h5>
                <u>Part 1: Sharing developed promising practices toolkit</u>
              </h5>
              <p>
                <ul>
                  <li>
                    Participants will learn successful promising practices about
                    coalitions and capacity building developed from focus group
                    strategies to advance upstream health equity work in state
                    and local health departments
                  </li>
                </ul>
              </p>
              <h5>
                <u>Part 2: Applying what you learned and embedding strategy</u>
              </h5>
              <p>
                <ul>
                  <li>
                    Participants will learn to apply promising practices to
                    build strategic systems, institutions, and community
                    partnerships with whole community representation.
                  </li>
                </ul>
              </p>
              <div className="button-container">
                <Button
                  href="https://us02web.zoom.us/meeting/register/tZYkcO2rpzkiGNFhqxX3IsgVY1PicxzpFjE2"
                  target="_Blank"
                >
                  Register for Session 1 Here!
                </Button>
              </div>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                Session 2: Asian American Session and Dialogue and Development
                of Promising Practices
              </h3>
              <p className="subheader">
                September 21st, 2022 - 11 AM-12:30 PM PST
              </p>
              <h4>Featured Speakers</h4>
              <p className="speakers">
                <ul>
                  <li>
                    <strong>Scarlett Lin Gomez</strong> -
                    <em>
                      Professor, Department of Epidemiology and Biostatistics,
                      Helen Diller Family Comprehensive Cancer Center at the
                      University of San Francisco and Director, Greater Bay Area
                      Cancer Registry (GBACR)
                    </em>
                  </li>
                  <li>
                    <strong>Elaine Ishihara</strong> -
                    <em>
                      Director of the Asian Pacific Islander Coalition
                      Advocating Together for Health (APICAT)
                    </em>
                  </li>
                </ul>
              </p>

              <h4>Objectives</h4>
              <h5>
                <u>
                  Part 1: Creating a Dialogue and Developing Promising Practices
                </u>
              </h5>
              <p>
                <ul>
                  <li>
                    Participants will learn to leverage a community centered
                    asset based approach to coalitions and capacity building and
                    develop strategies to advance upstream health equity work in
                    state and local health departments through cross cultural
                    community partnerships to empower communities to civic
                    engagement and leadership
                  </li>
                  <li>
                    Participants will learn to identify successful promising
                    practices and how to apply them to build strategic systems,
                    institutions, and community partnerships with whole
                    community
                  </li>
                </ul>
              </p>
              <h5>
                <u>
                  Part 2: Applying What You Learned - Embedding and
                  Standardizing Promising Practices
                </u>
              </h5>
              <p>
                <ul>
                  <li>
                    Participants will develop ways to uplift promising practices
                    to advance health equity for all and empower communities to
                    champion equity centered policy and culturally centered
                    education and outreach
                  </li>
                </ul>
              </p>
              <div className="button-container">
                <Button
                  href="https://us02web.zoom.us/meeting/register/tZ0pdO6spzspG9Ifo9uz95Bd1trEd_DmmnyZ"
                  target="_Blank"
                >
                  Register for Session 2 Here!
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </body>
      <div className="footer">
        <Container>
          <Row>
            <Col>
              <div className="footer-header">
                <img src={Appeal} />
                <br />
                This web series is sponsored by APPEAL.
              </div>
              <div className="footer-text">
                For over 27 years, Asian Pacific Partners for Empowerment,
                Advocacy, and Leadership (APPEAL) has been leading this effort
                through their work in the Asian American, Native Hawaiian and
                Pacific Islander communities. APPEAL is a national non-profit
                working to advance health equity and to eliminate tobacco and
                cancer health related disparities in all with a focus on Asian
                American, Native Hawaiian, and Pacific Islander communities (AA
                &amp; NH/PI). APPEAL implements the ASPIRE Network. ASPIRE is a
                national network that aims to build community capacity and
                facilitate the development of tobacco and cancer policy
                initiatives among the diverse AA &amp; NH/PI populations.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
